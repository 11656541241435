@import "custom";
@import "bootstrap";
// @import "_social";
// @import "_banner";

html {
  position: relative;
  min-height: 100%;
}

%footer-shared {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.footer {
  // @extend %footer-shared;
  // min-height: 430px; /* Set the fixed height of the footer here */

  .pinkimg {
    width: 600px;
    position: absolute;
    left: -400px;
    bottom: 60px;
  }
  .yellowimg {
    position: absolute;
    right: -180px;
    bottom: 60px;
  }
}
.footer-plain {
  @extend %footer-shared;
  height: 140px; /* Set the fixed height of the footer here */
}

.footer-inner {
  padding-bottom: 80px;
  border-bottom: 2px solid $teal;
}

.short-text {
  overflow: hidden;
  height: 3.3rem;
}

.full-text {
  height: auto;
}
a:hover {
  text-decoration: none;
}

.flag {
  background-repeat: no-repeat;
  background-size: contain;
  float: left;
  height: 16px;
  margin: 2px 6px 0 0;
  width: 32px;
}

.flag.uk {
  background-image: url(./../../public/assets/img/flags/uk-64.png);
}
.flag.us {
  background-image: url(./../../public/assets/img/flags/us-64.png);
}
.flag.au {
  background-image: url(./../../public/assets/img/flags/au-64.png);
}
.flag.nz {
  background-image: url(./../../public/assets/img/flags/nz-64.png);
}
.flag.ie {
  background-image: url(./../../public/assets/img/flags/ie-64.png);
}
.flag.cy {
  background-image: url(./../../public/assets/img/flags/cy-64.png);
}

.xdsoft_autocomplete,
.xdsoft_autocomplete div,
.xdsoft_autocomplete span {
  width: 100% !important;
}

.pinkandyellowrectanglebig {
  background-color: $yellow;
  border-radius: 0.5em;
  transform: translateY(-50%);

  .pink-side {
    background-color: $pink;
    border-top-right-radius: 0.5em;
    border-bottom-right-radius: 0.5em;

    button {
      font-size: 18px;
      font-weight: bold;
    }
  }
}

.pinkandyellowrectanglesmall {
  background-color: $yellow;
  border-radius: 0.5em;
  transform: translateY(-10%);

  .pink-square {
    background-color: $pink;
    border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
    button {
      font-size: 18px;
      font-weight: bold;
    }
  }
}

.highlight {
  font-size: 30px;
  color: $primary;
  padding-top: 34px;
}

#video-section .modal-content {
  min-width: 600px;
}

#video-section .modal-content iframe {
  width: 560px;
  height: 315px;
}

// .pinkA {
//   background-image: url("/assets/imgv2/A@2x.png");
//   background-size: 50%;
//   background-repeat: no-repeat;
//   background-position: -63% -50%;
// }

// .yellowA {
//   position: relative;
//   background-image: url("/assets/imgv2/A@2x.png");
//   background-size: 50%;
//   background-repeat: no-repeat;
//   background-position: right -63% bottom -120px;
//   padding-bottom: 120px;
// }

.bannerimage {
  position: relative;
}

.bannertext {
  position: absolute;
  color: #fff;
  left: 21%;
  right: 0;
  top: 10%;
  width: 45%;
  p {
    font-size: 30px;
  }
}

.localbannertext {
  position: absolute;
  color: #fff;
  left: 22%;
  top: 10%;
  width: 55%;
  p {
    font-size: 30px;
  }
}

.pink-circle {
  height: 87px;
  width: 87px;
  background-color: $primary;
  border-radius: 50%;
}

#listingsCarousel,
#articlesCarousel {
  .card {
    height: 450px;
  }
}

.card-text {
  font-size: 18px;
}

.box-links {
  .card {
    max-width: 540px;
  }
  .bookimg {
    height: 150px;
    position: absolute;
    bottom: -20px;
    right: 0px;
  }
}

// Search box and search icon in local home navbar menu

div.search-outer {
  height: 104px;
  .form-control:focus {
    box-shadow: none;
  }
}

div.search-icon {
  width: 60px;
  svg {
    display: block;
    height: 100%;
    width: 100%;
  }
}

.xdsoft_autocomplete .xdsoft_autocomplete_dropdown > div.active {
  background: $secondary;
}

.form-control:focus .xdsoft_input {
  color: $primary;
}

.classselect {
  border-bottom: 50px solid;
  border-image-slice: 1;
  border-width: 13px;
  border-image-source: linear-gradient(to right, $primary, $secondary);
}

label {
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 0;
}

blockquote {
  margin: 0;
}

blockquote p {
  padding: 15px;
  background: #eee;
  border-radius: 5px;
}

blockquote p::before {
  content: "\201C";
}

blockquote p::after {
  content: "\201D";
}

.stepcard.card {
  border: 1px solid $white;
}

.stepcard.card:hover {
  border: 2px solid $primary;
}

.octicon {
  display: inline-block;
  vertical-align: text-top;
  fill: currentColor;
}

ol li::marker {
  font-weight: bold;
}
