// Your variable overrides
// $body-bg: #000;

// Color system

$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e6e6e6 !default;
$gray-300: #dedede !default;
$gray-400: #18304040 !default;
$gray-500: #cecece !default;
$gray-600: #18304080 !default;
$gray-700: #686868 !default;
$gray-800: #454d5a !default;
$gray-900: #212529 !default;
$black: #000 !default;

$grays: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$grays: map-merge(
  (
    "100": $gray-100,
    "200": $gray-200,
    "300": $gray-300,
    "400": $gray-400,
    "500": $gray-500,
    "600": $gray-600,
    "700": $gray-700,
    "800": $gray-800,
    "900": $gray-900,
  ),
  $grays
);

// $theme-colors:(
//   (
//     "dark": #1e2f3e,
//     "primary": #1e2f3e,
//     "info": #0b4580,
//     "warning": #f97510
//   )
// );

$blue: rgb(24, 48, 64) !default;
$indigo: #325770 !default;
$purple: #6f42c1 !default;
$pink: hsl(327, 73%, 53%) !default;
$red: #e13c92 !default;
$orange: #fd7e14 !default;
$yellow: #fefc95 !default;
$green: #28a745 !default;
$teal: #669fc5 !default;
$cyan: #17a2b8 !default;

$colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$colors: map-merge(
  (
    "blue": $blue,
    "indigo": $indigo,
    "purple": $purple,
    "pink": $pink,
    "red": $red,
    "orange": $orange,
    "yellow": $yellow,
    "green": $green,
    "teal": $teal,
    "cyan": $cyan,
    "white": $white,
    "gray": $gray-600,
    "gray-dark": $gray-800,
  ),
  $colors
);

$body-color: $blue;

$primary: $pink !default;
$secondary: $yellow !default;
$success: $cyan !default;
$info: $indigo !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-100 !default;
$dark: $blue !default;

$theme-colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
  (
    "primary": $primary,
    "secondary": $secondary,
    "success": $success,
    "info": $info,
    "warning": $warning,
    "danger": $danger,
    "light": $light,
    "dark": $dark,
  ),
  $theme-colors
);

$input-placeholder-color: rgba(24, 48, 64, .4);

$spacer: 1rem !default;
$font-family-sans-serif: "Gilroy", "Arial", "Helvetica Neue" !default;
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
  "Liberation Mono", "Courier New", monospace !default;
$font-family-base: $font-family-sans-serif !default;
// stylelint-enable value-keyword-case

$font-size-base: 1.25rem !default; // Assumes the browser default, typically `16px`
$font-size-lg: $font-size-base * 1.25 !default;
$font-size-sm: $font-size-base * 0.875 !default;
$font-weight-lighter: lighter !default;
$font-weight-light: 300 !default;
$font-weight-normal: 500 !default;
$font-weight-bold: 700 !default;
$font-weight-bolder: bolder !default;

$font-weight-base: $font-weight-normal !default;
$line-height-base: 1.5 !default;

$h1-font-size: $font-size-base * 3.5 !default;
$h2-font-size: $font-size-base * 2 !default;
$h3-font-size: $font-size-base * 1.75 !default;
$h4-font-size: $font-size-base * 1.5 !default;
$h5-font-size: $font-size-base * 1.25 !default;
$h6-font-size: $font-size-base !default;

$headings-margin-bottom: $spacer / 2 !default;
$headings-font-family: "Gilroy", "Arial", "Helvetica Neue" !default;
$headings-font-weight: $font-weight-bold !default;
$headings-line-height: 1.2 !default;
$headings-color: null !default;

$display1-size: 6rem !default;
$display2-size: 5.5rem !default;
$display3-size: 4.5rem !default;
$display4-size: 3.5rem !default;

$display1-weight: 300 !default;
$display2-weight: 300 !default;
$display3-weight: 300 !default;
$display4-weight: 300 !default;
$display-line-height: $headings-line-height !default;

$enable-responsive-font-sizes: true;

$list-group-action-color: map-get($theme-colors, "dark");

$white: #fff !default;
$navbar-dark-color: rgba($white, 0.75) !default;
$navbar-dark-hover-color: rgba($white, 0.85) !default;

$navbar-light-color: rgba($dark, 0.9) !default;
$navbar-light-hover-color: rgba($dark, 0.7) !default;
$navbar-light-active-color: rgba($dark, 0.5) !default;

$navbar-padding-y: 0 !default;
$navbar-padding-x: 0 !default;

$border-color: $gray-200 !default;

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem 1.75rem .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='rgb(223, 49, 146)' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right .75rem center/8px 10px;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
